import React, { useState } from "react";

const StarRating = ({ totalStars = 5, rating, setRating }) => {
  //   const [rating, setRating] = useState(0);

  // Function to handle when a user clicks on a star
  const handleClick = (index) => {
    setRating(index + 1); // Index starts at 0, so we add 1
  };

  console.log("rating:", rating);

  return (
    <div style={styles.container}>
      {Array.from({ length: totalStars }, (_, index) => (
        <Star
          key={index}
          filled={index < rating}
          onClick={() => handleClick(index)}
        />
      ))}
    </div>
  );
};

// Star component to render individual stars
const Star = ({ filled, onClick }) => {
  return (
    <span
      onClick={onClick}
      style={{
        cursor: "pointer",
        color: filled ? "#FFD700" : "#CCC", // Gold if filled, otherwise light grey
        fontSize: "3rem",
      }}
    >
      &#9733; {/* Unicode star character */}
    </span>
  );
};

const styles = {
  container: {

    gap: "10px",
  },
};

export default StarRating;