import React, { useState, useEffect } from "react";
import Table from "../Components/Table";
import RestService from "../Services/api.service";
import SkeletonLoader from "../Components/SkeletonLoader";
import { ToastContainer } from 'react-toastify';
import ToastMessage from "../Store/ToastHook";

const NewDashboard = () => {
    const headers = ['Job Name', 'Date', 'Status'];
    const [allJobs, setAllJobs] = useState([]);
    const [jobsApplied, setjobsApplied] = useState([]);
    const [completedJob, setCompletedJob] = useState(0);
    const [loader, setLoader] = useState(false);
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;

    useEffect(() => {
        if (user && user.user.type === "company") {
            viewAllAppliedJobs();
        } else if (user && user.user.type === "user") {
            viewAllJobs();
        }
    }, []);

    // Fetch all jobs posted
    const viewAllJobs = () => {
        setLoader(true);
        RestService.viewAllJobs().then(response => {
            if (response.status === 200) {
                const completedJobCount = response.data.jobs?.filter(job => job.status === 'completed')?.length || 0;
                const newJobs = response.data.jobs?.map(job => ({
                    id: job._id,
                    userName: job.title,
                    date: job.jobStartDate?.substring(0, 10),
                    status: job.status === 'completed' ? 'Completed' : job.status === 'in-progress' ? "In-Progress" : "Open"
                })) || [];

                setAllJobs(newJobs);
                setCompletedJob(completedJobCount);
            } else {
                setAllJobs([]);
                ToastMessage({ type: "error", message: "Failed to fetch jobs", time: 2500 });
            }
        }).catch(err => {
            console.error("Error occurred while fetching jobs", err);
            ToastMessage({ type: "error", message: "Error fetching jobs", time: 2500 });
        }).finally(() => {
            setLoader(false);
        });
    };

    // Fetch jobs applied by user
    const viewAllAppliedJobs = () => {
        setLoader(true);
        RestService.getJobAppliedByUsers(user.user._id).then(response => {
            if (response.status === 200) {
                const completedJobCount = response.data.jobsApplied?.filter(job => job.status === 'completed')?.length || 0;
                const newJobs = response.data.jobsApplied?.map(job => ({
                    id: job._id,
                    userName: job.title,
                    date: job.jobStartDate?.substring(0, 10),
                    status: job.status === 'completed' ? 'Completed' : job.status === 'in-progress' ? "In-Progress" : "Open"
                })) || [];

                setAllJobs(newJobs);
                setjobsApplied(response.data.jobsApplied);
                setCompletedJob(completedJobCount);
            } else {
                setjobsApplied([]);
                ToastMessage({ type: "error", message: "Failed to fetch applied jobs", time: 2500 });
            }
        }).catch(err => {
            console.error("Error occurred while fetching applied jobs", err);
            ToastMessage({ type: "error", message: "Error fetching applied jobs", time: 2500 });
        }).finally(() => {
            setLoader(false);
        });
    };

    // Delete a job
    const deleteJob = (jobId) => {
        setLoader(true);
        RestService.deleteJob(jobId).then(response => {
            if (response.status === 200) {
                user.user.type === "company" ? viewAllAppliedJobs() : viewAllJobs();
            }
        }).catch(err => {
            ToastMessage({ type: "error", message: err.response.data.errorMessage, time: 2500 });
            console.error("Error occurred while deleting job", err);
        }).finally(() => {
            setLoader(false);
        });
    };

    return (
        <>
            <ToastContainer />
            <main style={{ background: "#fff" }}>
                <div className="head-title">
                    <div className="left">
                        <h1>Dashboard</h1>
                        <ul className="breadcrumb">
                            <li><a href="#">Dashboard</a></li>
                            <li><i className='bx bx-chevron-right'></i></li>
                            <li><a className="active" href="#">Home</a></li>
                        </ul>
                    </div>
                </div>
                <ul className="box-info">
                    <li>
                        <i className='bx bxs-calendar-check'></i>
                        <span className="text">
                            <h3>
                                {loader ? 
                                    <SkeletonLoader skeletonCount={1.5} /> 
                                    : 
                                    (user?.user?.type === 'user' ? allJobs?.length || 0 : jobsApplied?.length || 0)
                                }
                            </h3>
                            <p>{user?.user?.type === 'user' ? 'Job Posted' : 'Job Applied'}</p>
                        </span>
                    </li>
                    <li>
                        <i className='bx bxs-group'></i>
                        <span className="text">
                            <h3>{loader ? <SkeletonLoader skeletonCount={1.5} /> : completedJob}</h3>
                            <p>Completed Jobs</p>
                        </span>
                    </li>
                 
                </ul>
                {
                    loader ? (
                        <SkeletonLoader skeletonCount={10} />
                    ) : 
                    user?.user?.type === 'admin' ?
                    <></>
                    :
                    (
                        <div className="table-data">
                            {allJobs.length > 0 ? (
                                <Table headers={headers} data={allJobs} deleteJob={deleteJob} />
                            ) : (
                                <p>No jobs to show</p>
                            )}
                        </div>
                    )
                }
            </main>
        </>
    );
};

export default NewDashboard;
