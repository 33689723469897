import React, { useState, useEffect } from "react";
import Table from "../Components/Table";
import RestService from "../Services/api.service";
import SkeletonLoader from "../Components/SkeletonLoader";
import { ToastContainer } from 'react-toastify';

const AllJobs = () => {
  const headers = ['Job Name', 'Posted Date', 'Posted By', 'Status'];
  const [allJobs, setAllJobs] = useState([]);
  const [loader, setLoader] = useState(false);


  // Fetch all jobs
  const getAllJobs = () => {
    setLoader(true);
    RestService.getAllJobs().then(response => {
      if (response.status === 200) {

        const allJobs = response.data?.jobs?.map(job => ({
          id: job._id,
          title: job.title,
          jobStartDate: job?.jobStartDate?.substring(0, 10),
          postedBy: job?.postedBy ? job?.postedBy?.name : '---',
          status: job.status,
        }))

        setAllJobs(allJobs);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      } else {
        setLoader(false);
        setAllJobs([]);
      }
    }).catch(err => {
      console.error("Error occurred while fetching all jobs", err);
      setLoader(false);
      setAllJobs([]);
    })
  };

  useEffect(() => {
    getAllJobs();
  }, []);

  return (
    <>
      <ToastContainer />
      <main style={{ background: "#fff" }}>
        {
          loader ? (
            <SkeletonLoader skeletonCount={10} />
          ) : (
            <div className="table-data">
              {allJobs?.length > 0 ? (
                <Table headers={headers} data={allJobs} headerTitle='All Jobs'/>
              ) : (
                <p>No jobs to show</p>
              )}
            </div>
          )
        }
      </main>
    </>
  );
};

export default AllJobs;
