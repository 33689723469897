import React from "react";
import { Link } from "react-router-dom";
import { COMPANY_LOGO } from "./Icon";

const Footer = () => {
    return (
        <>
            <footer>
                <section class="df-footer__area section-spacing-top bg-theme-1">

                    <div class="footer__widgets-area">
                        <div class="container">
                            <div class="footer__widgets-wrapper widgets-5">
                                <div class="footer__widget">
                                    <div class="df-footer__logo mb-30">
                                        <a href="/">{COMPANY_LOGO}</a>
                                    </div>
                                    <p class="df-footer-text is-white max-225">It can help to identify areas for
                                        improvement</p>
                                </div>
                                <div class="footer__widget">
                                    <h4 class="footer__widget-title text-white">Services</h4>
                                    <div class="footer__links underline is-white">
                                        <ul>
                                            <li><a href="#!">Drain Cleaning</a></li>
                                            <li><a href="#!">Gasoline Service</a></li>
                                            <li><a href="#!">Kitchen Plumbing</a></li>
                                            <li><a href="#!">Pipe Leaking</a></li>
                                            <li><a href="#!">Bathroom</a></li>
                                            <li><a href="/other-services">Other Services</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="footer__widget">
                                    <h4 class="footer__widget-title text-white">Useful Links</h4>
                                    <div class="footer__links underline is-white">
                                        <ul>
                                            <li><a href="#!">About Us</a></li>
                                            <li><a href="#!">Our Team</a></li>
                                            <li><a href="#!">Clients Feedback</a></li>
                                            <li><Link to="/contactus">Contact</Link></li>
                                            <li><a href="#">Coming Soon</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="footer__widget">
                                    <h4 class="footer__widget-title text-white">Contact Info</h4>
                                    <div class="footer-meta mb-15 is-white">
                                        <i class="icon-030-pin"></i>
                                        <p>2784 Ivah Fields, <br /> Jonesville, Virginia, USA.</p>
                                    </div>
                                    <div class="footer-meta is-white">
                                        <i class="icon-timelapse"></i>
                                        <p>Work time: <br />
                                            Sun - Fri 09am - 8pm</p>
                                    </div>
                                </div>
                                <div class="footer__widget">
                                    <h4 class="footer__widget-title text-white">Social Links</h4>
                                    <div class="social-links is-white">
                                        <ul>
                                            <li><a href="https://www.facebook.com/"><i class="icon-023-facebook-app-symbol"></i></a>
                                            </li>
                                            <li><a href="https://www.instagram.com/"><i class="icon-025-instagram"></i></a>
                                            </li>
                                            <li><a href="https://www.pinterest.com/"><i class="icon-029-pinterest"></i></a>
                                            </li>
                                            <li><a href="https://twitter.com/"><i class="icon-twitter-x"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="bg-theme-1">
                    <div class="container">
                        <div class="hr1 is-white"></div>
                    </div>
                </div>


                <div class="copyright-area p-relative bg-theme-1">
                    <div class="container">
                        <div class="copyright-content__wrapper">
                            <div class="copyright__text is-white">
                                <p>© All Rights Reserved by <a href="/" target="_blank">Tenders Space</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    );
};

export default Footer;
