import React, { useState } from "react";
import RestService from "../../Services/api.service";
import ToastMessage from "../../Store/ToastHook";
import { ToastContainer } from "react-toastify";
import ButtonLoader from "../../Components/ButtonLoader";
import { useNavigate } from "react-router-dom";

const EditCompanyProfile = () => {
    const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : "";

    const [name, setName] = useState(user?.user?.name);
    const [email] = useState(user?.user?.email); // email is static
    const [cacnumber] = useState(user?.user?.cacnumber); // CAC number is static
    const [phoneNumber, setPhoneNumber] = useState(user?.user?.phoneNumber);
    const [address, setAddress] = useState(user?.user?.address);
    const [pincode, setPincode] = useState(user?.user?.pincode);
    const [description, setDescription] = useState(user?.user?.description);
    const [buttonLoader, setButtonLoader] = useState(false);

    // State for file upload and image preview
    const [uploadedImage, setUploadedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(user?.user?.photoUrl ? user?.user?.photoUrl : "https://via.placeholder.com/150"); // Dummy URL

    const navigate = useNavigate();

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file && file.type.startsWith("image/")) {
            setUploadedImage(file);
            setImagePreview(URL.createObjectURL(file)); // Create a preview URL for the image
        } else {
            ToastMessage({ type: "error", message: "Please upload a valid image file", time: 2500 });
        }
    };

    const handleChangeName = (event) => {
        const newValue = event.target.value;
        if (/^[A-Za-z\s]*$/.test(newValue)) {
            setName(newValue);
        }
    };

    const handleChangeAddress = (e) => {
        setAddress(e.target.value);
    };

    const handleChangePincode = (e) => {
        setPincode(e.target.value);
    };

    const handleChangeDescription = (e) => {
        setDescription(e.target.value);
    };

    const handleChangePhoneNumber = (e) => {
        setPhoneNumber(e.target.value);
    };

    const editCompany = () => {
        setButtonLoader(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("phoneNumber", phoneNumber);
        formData.append("address", address);
        formData.append("pincode", pincode);
        formData.append("description", description);
        formData.append("images", uploadedImage);
        // const payload = {
        //     name,
        //     phoneNumber,
        //     address,
        //     pincode,
        //     description,
        // };

        RestService.editCompany(formData)
            .then(response => {
                if (response.status === 200) {
                    let parsedData = user;
                    parsedData.user = response?.data?.user;
                    localStorage.setItem('user', JSON.stringify(parsedData));

                    ToastMessage({ type: "success", message: 'Company Profile Updated Successfully', time: 2500 });
                    setButtonLoader(false);
                }
            })
            .catch(() => setButtonLoader(false));
    };

    return (
        <>
            <ToastContainer />
            <main>
                <div className="adjust-header-space bg-common-white"></div>
                <section className="df-contact__area section-spacing-top p-relative fix">
                    <div className="container">
                        <div className="row justify-content-center section-title-spacing">
                            <div className="col-xl-8 text-center">
                                <h2>Update Company Profile</h2>
                            </div>
                        </div>

                        <div className="row" style={{ marginBottom: "100px" }}>
                            <div className="df-booking2__form">
                                {/* File upload */}
                                <div className="row mb-4 text-center">
                                    <div className="col-md-12">
                                        <label className="form-label">Upload Profile</label>
                                        <div>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                                style={{ display: "none" }}
                                                id="fileUpload"
                                            />
                                            <label htmlFor="fileUpload" className="btn btn-secondary">
                                                Choose Image
                                            </label>
                                        </div>
                                        <div>
                                            <img
                                                src={imagePreview}
                                                alt="Company Logo"
                                                style={{
                                                    width: "150px",
                                                    height: "150px",
                                                    borderRadius: "50%",
                                                    objectFit: "cover",
                                                    marginTop: "10px"
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row gx-5">
                                    <div className="col-md-6">
                                        <label className="form-label">Name</label>
                                        <input
                                            type="text"
                                            value={name}
                                            onChange={handleChangeName}
                                            placeholder="Company Name *"
                                            className="df-input-field"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Email</label>
                                        <input
                                            type="text"
                                            value={email}
                                            placeholder="Email *"
                                            className="df-input-field"
                                            disabled
                                        />
                                    </div>

                                    <div class="col-md-6">
                                        <label className="form-label">Contact Number</label>
                                        <div class="df-input-field">
                                            <input type="text" id="number" name="number" placeholder="Contact Number *"
                                                value={phoneNumber}
                                                onChange={handleChangePhoneNumber}
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label className="form-label">CAC Number</label>
                                        <div class="df-input-field">
                                            <input type="text" name="number" placeholder="CAC Number *"
                                                defaultValue={cacnumber} disabled />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label className="form-label">Address</label>
                                        <div class="df-input-field">
                                            <input type="text" name="Address" placeholder="Address *" value={address}
                                                onChange={handleChangeAddress} />

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label className="form-label">Pincode</label>
                                        <div class="df-input-field ">
                                            <input type="text" name="Postal code" placeholder="Postal code *" value={pincode}
                                                onChange={handleChangePincode} />

                                        </div>
                                    </div>



                                </div>
                                <div className="row">
                                    <label className="form-label">Description</label>
                                    <div class="df-input-field ">
                                        <textarea rows={5} cols={10} type="text" name="description" placeholder="Company Description"
                                            value={description}
                                            onChange={handleChangeDescription}
                                        />

                                    </div>

                                </div>


                            </div>

                            <div className="df-booking2__form-btn mt-15 mb-30">
                                <button
                                    type="submit"
                                    className="primary-btn sign-btn w-100"
                                    disabled={
                                        buttonLoader ||
                                        name?.length === 0 ||
                                        phoneNumber?.length !== 10 ||
                                        address?.length < 5 ||
                                        pincode?.length !== 6 ||
                                        description?.length === 0 ||
                                        uploadedImage === null
                                    }
                                    onClick={editCompany}
                                >
                                    {buttonLoader ? <ButtonLoader loadingMessage="Updating..." /> : 'Update Profile'}
                                </button>

                            </div>
                        </div>
                    </div>

                </section>

            </main >
        </>
    );
};

export default EditCompanyProfile;
