import React, { useState, useEffect } from 'react'
import { useLocation, } from 'react-router-dom';
import RestService from '../../Services/api.service';
import SkeletonLoader from '../../Components/SkeletonLoader';
import StarRating from '../../Components/StarRating';

const CompanyProfile = () => {
    const location = useLocation();
    const { name, email, phoneNumber, address, pincode, subcategory, description, type, photoUrl, companyId } = location.state || {};  // Access the title from state
    const [loaderCompany, setLoaderCompany] = useState(true);
    const [allReviewsAndRating, setAllReviewsAndRating] = useState([]);

    //get reviews by company id
    const getJobReviewsById = () => {
        setLoaderCompany(true);

        RestService.getJobReviewsById(companyId).then(
            response => {
                if (response.status === 200) {
                    console.log(response.data.reviews)
                    setAllReviewsAndRating(response.data.reviews);
                    setTimeout(() => {
                        setLoaderCompany(false);
                    }, 1000);
                }
            }
        ).catch(err => {
            setLoaderCompany(false);
            setAllReviewsAndRating([]);
            console.error("Error occurred on getJobReviewsById", err);
        });
    };

    useEffect(() => {
        getJobReviewsById();
    }, []);


    return (
        <>
            <section class="df-specialities__area section-spacing">
                <div class="container">
                    <div class="row g-50 align-items-center">
                        <div class="col-lg-6 order-lg-0 order-1">
                            <div class="df-service4__thumb-wrapper p-relative">
                                <div class="df-service4__thumb">
                                    <img src={photoUrl ? photoUrl : "assets/img/service/ig-plumbing.webp"} alt="img not found" />
                                </div>
                                <div class="service-meta__wrapper">
                                    <div class="service-meta__item">
                                        <div class="service-meta__icon">
                                            <i class="icon-074-phone"></i>
                                        </div>
                                        <div class="service-meta__text">
                                            {/* <h4 class="service-meta__title underline">Call <a href="tel:866-332-2020">
                                                866-332-2020</a> <br />Anytime For Free.</h4> */}
                                            <h4 class="service-meta__title underline">Call <a href={`tel:${phoneNumber}`}>
                                                {phoneNumber}</a> <br />Anytime For Free.</h4>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 order-lg-1 order-0">
                            <div class="df-service4__content">
                                <div class="section__title-wrapper">
                                    {/* <h2 class="section__title">ABCD Pest Control</h2> */}
                                    <h2 class="section__title" style={{ marginTop: "100px" }}>{name} : {type}</h2>
                                </div>
                                <p class="mt-35 mb-35"> {description ? description : ''} </p>
                                <div class="df-service4__feature-list sericon mb-40">
                                    <ul>
                                        <li>
                                            <span class="list-icon">
                                                <i class="fas fa-headset"></i>
                                            </span>
                                            <p>Get call from the company</p>
                                        </li>
                                        <li>
                                            <span class="list-icon">
                                                <i class="fas fa-map-marked-alt"></i>
                                            </span>
                                            {/* <p>Lyngbøkollen 101, Laksevåg</p> */}
                                            <p>{`${address}, ${pincode}`}</p>
                                        </li>
                                        <li>
                                            <span class="list-icon">
                                                <i class="fas fa-phone-alt"></i>
                                            </span>
                                            {/* <p>Organization no.  866-332-2020</p> */}
                                            <p>Organization no.  {phoneNumber}</p>
                                        </li>
                                        <li>
                                            <span class="list-icon">
                                                <i class="fas fa-envelope"></i>
                                            </span>
                                            {/* <p>company@comapnyemail.com</p> */}
                                            <p>{email}</p>
                                        </li>
                                        <li>
                                            <span class="list-icon">
                                                <i class="fas fa-star"></i>
                                            </span>
                                            <p>Total Evaluations : 46 - Rated 4.5</p>
                                        </li>

                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="df-contact__area section-spacing-top p-relative fix">
                <div class="circle-2"></div>
                <div class="circle-3"></div>
                <div class="container">
                    <div class="row justify-content-center section-title-spacing wow fadeInUp" data-wow-delay=".3s">
                        <div class="col-xl-8">
                            <div class="section__title-wrapper text-center">
                                <h2 class="section__title">All other services offered by the company</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row g-5" style={{ paddingBottom: "100px" }}>
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="df-service4__feature-list mb-40 companyser">
                                {subcategory && subcategory?.map((service) => {
                                    return (
                                        <ul>

                                            <a href="">
                                                <li className='mb-40'>
                                                    <span class="list-icon">
                                                        <i class="icon-058-check"></i>
                                                    </span>
                                                    <p>{service}</p>
                                                </li>
                                            </a>

                                        </ul>
                                    )
                                })}

                            </div>
                        </div>
                    </div>

                </div>

            </section>
            {
                loaderCompany ?
                    <SkeletonLoader skeletonCount={5} />
                    :

                    <div class="section testmon">
                        {allReviewsAndRating.map((index) => {
                            return (
                                <>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div class="df-testimonial2__box">
                                                    <div class="testborder">
                                                        <hr />
                                                    </div>
                                                    <div class="df-testimonial2__box-content">
                                                        <div class="df-testimonial2__icon">
                                                            <i class="icon-020-quote"></i>
                                                        </div>
                                                        <div class="df-testimonial2__text">
                                                            <p>
                                                                {index?.review}
                                                            </p>
                                                        </div>
                                                        <div class="df-testimonial2__author-meta">
                                                            <div class="df-testimonial2__author-thumb">
                                                                <img src="assets/img/clients/client-01.webp" alt="image not found" />
                                                            </div>
                                                            <div class="df-testimonial2__author-review">
                                                                <h4 class="df-testimonial2__author">{index?.userId?.name}</h4>
                                                                <div class="df-satisfaction__ratings">
                                                                    <StarRating rating />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="testborder">
                                                        <hr />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
            }

        </>
    )
}

export default CompanyProfile