import React, { useState, useEffect } from "react";
import Table from "../Components/Table";
import RestService from "../Services/api.service";
import SkeletonLoader from "../Components/SkeletonLoader";
import { ToastContainer } from 'react-toastify';

const AllUsers = () => {
  const headers = ['Email', 'Phone Number', 'Total Job Posted', 'Job Applied', 'Type'];
  const [allUsers, setAllUsers] = useState([]);
  const [loader, setLoader] = useState(false);


  // Fetch all users
  const getAllUsers = () => {
    setLoader(true);
    RestService.getAllUsers().then(response => {
      if (response.status === 200) {

        const allUsers = response.data?.users?.map(user => ({
          id: user._id,
          email: user.email,
          phoneNumber: user?.phoneNumber ? user?.phoneNumber : '---',
          totalPostedJobs: user.totalPostedJobs,
          totalAppliedJobs: user.totalAppliedJobs,
          type: user.type
        }))

        setAllUsers(allUsers);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      } else {
        setLoader(false);
        getAllUsers([]);
      }
    }).catch(err => {
      console.error("Error occurred while fetching users", err);
      setLoader(false);
      getAllUsers([]);
    })
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <>
      <ToastContainer />
      <main style={{ background: "#fff" }}>
        {
          loader ? (
            <SkeletonLoader skeletonCount={10} />
          ) : (
            <div className="table-data">
              {allUsers?.length > 0 ? (
                <Table headers={headers} data={allUsers} headerTitle='All Users' />
              ) : (
                <p>No users to show</p>
              )}
            </div>
          )
        }
      </main>
    </>
  );
};

export default AllUsers;
