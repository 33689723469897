import React, { useState } from 'react';
import './Table.css';
import RestService from "../Services/api.service";
import BsModal from './Modal/Modal';
import Select from 'react-select';
import StarRating from './StarRating';
import ButtonLoader from './ButtonLoader';
import ToastMessage from '../Store/ToastHook';
import { ToastContainer } from 'react-toastify';

const Modal = ({ onClose, children }) => {
    return (
        <div style={modalOverlayStyle}>
            <div style={modalContentStyle}>
                <button style={modalCloseStyle} onClick={onClose}>X</button>
                {children}
            </div>
        </div>
    );
};

const Table = ({ headers, data, deleteJob, headerTitle }) => {
    const [loader, setLoader] = useState(false);
    const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : "";

    const [activeDropdown, setActiveDropdown] = useState(null);
    const [tableData, setTableData] = useState(data);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [type, setType] = useState('');
    const [jobId, setJobId] = useState('');
    const [rating, setRating] = useState(0);
    const [applicants, setApplicants] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);
    const [applicantList, setApplicantList] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [description, setDescription] = useState(user?.user?.description);

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];


    const handleEdit = (index) => {
        console.log(`Edit action for item at index ${index?.id}`);
        setActiveDropdown(null); // Close dropdown after action
    };

    const handleDelete = (index) => {
        console.log(`Delete action for item at index ${index?.id}`);
        deleteJob(index?.id);
        setActiveDropdown(null); // Close dropdown after action
    };

    const handleChangeDescription = (e) => {
        setDescription(e.target.value);
    };

    const toggleDropdown = (index) => {
        setActiveDropdown(activeDropdown === index ? null : index); // Toggle dropdown visibility
    };

    // Fetch applicants for a specific job
    const fetchApplicants = async (jobId) => {
        console.log(`Fetching applicants for jobId: ${jobId}`);
        setLoader(true);
        try {
            const response = await RestService.getJobApplicationsById(jobId);
            if (response.status === 200) {
                console.log("Applicants fetched successfully:", response.data.applicants);
                const applicantList = response.data.applicants?.map(applicant => ({
                    "value": applicant._id,
                    "label": applicant.name
                }));
                setApplicantList(applicantList);
                setApplicants(response.data.applicants);
            }
        } catch (err) {
            console.error("Error occurred on viewAllJobs", err);
        } finally {
            setLoader(false);
        }
    };

    const handleJobNameClick = async (job, type) => {
        if (user?.user?.type === "user") {
            if (job !== undefined) {
                console.log(`Job name clicked for job:`, job.userName);
                setSelectedJob(job.userName);

                // Fetch applicants for the selected job
                await fetchApplicants(job.id);

            }
            setType(type);
            setIsModalOpen(true); // Open the modal

        }
    };

    const sortDataByColumn = (columnKey) => {
        console.log(`Sorting by column: ${columnKey}`);
        const sortedData = [...tableData].sort((a, b) => {
            if (a[columnKey] < b[columnKey]) return -1;
            if (a[columnKey] > b[columnKey]) return 1;
            return 0;
        });
        setTableData(sortedData);
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'Completed':
                return 'status-green';
            case 'In-Progress':
                return 'status-orange';
            case 'Process':
                return 'status-blue';
            default:
                return 'status-default';
        }
    };

    const handleStatusChange = async (e, rowIndex, row) => {
        // console.log(`Status change for row ${rowIndex}, new status: ${e.target.value}, data: ${row}`);
        const status = e.target.value;

        setLoader(true);
        try {
            const updatedData = tableData.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...row,
                        status: status
                    };
                }
                return row;
            });
            setTableData(updatedData);
            const response = await RestService.completeJob(row.id);
            if (response.status === 200) {
                console.log("Applicants fetched successfully:", response.data.applicants);

                if (status === "Completed") {
                    setJobId(row.id);
                    setTimeout(() => {
                        handleJobNameClick(row, 'REVIEW');
                    }, 1000);
                }
            }
        } catch (err) {
            console.error("Error occurred on viewAllJobs", err);
        } finally {
            setLoader(false);
        }



    };

    const postJobReviews = () => {
        const userId = user?.user?._id;
        setButtonLoader(true);
        const payload = {
            "userId": userId,
            "companyId": selectedOption?.value,
            "jobId": jobId,
            "rating": rating,
            "review": description
        }

        RestService.postJobReviews(payload)
            .then(response => {
                if (response.status === 200) {

                    ToastMessage({ type: "success", message: 'Company Profile Updated Successfully', time: 2500 });
                    setButtonLoader(false);
                }
            })
            .catch(() => setButtonLoader(false));
    };


    const closeModal = () => {
        console.log("Closing modal");
        setIsModalOpen(false);
        setApplicants([]);
    };

    console.log("applicantList", applicantList);
    console.log("applicants", applicants);

    return (
        <>
            <div style={{ padding: "0", border: "none", boxShadow: "none" }}>   <ToastContainer /></div>
            <div className="order">
                <div className="head">
                    <h3>{headerTitle ? headerTitle : user?.user?.type === "company" ? 'Applied Job' : user?.user?.type === 'user' && 'Posted Job'}</h3>
                    <i className='bx bx-search'></i>
                    <i className='bx bx-filter'></i>
                </div>
                <table className="custom-table">
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index} onClick={() => sortDataByColumn(header.replace(/\s/g, '').toLowerCase())}>{header}</th>
                            ))}
                            {user?.user?.type === "user" && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {Object.keys(row).filter(key => key !== 'id').map((key, cellIndex) => (
                                    <td
                                        key={cellIndex}
                                        onClick={() => {
                                            if (key === 'userName' && user?.user?.type === "user") {
                                                console.log(`Title clicked for job: ${row.userName}, at row ${rowIndex}`);
                                                handleJobNameClick(row, 'APPLICANT');
                                            } else {
                                                console.log(`Clicked on ${key} for job: ${row.userName}`);
                                            }
                                        }}
                                        style={{
                                            cursor: key === 'title' && user?.user?.type === "user" ? 'pointer' : 'default',
                                        }}
                                    >
                                        {key === 'userImage' ? <img src={row[key]} alt="User" /> : key === 'status' ? user?.user?.type === 'user' ? (
                                            <select
                                                value={row[key]}
                                                onChange={(e) => handleStatusChange(e, rowIndex, row)}
                                                className={`status-select ${getStatusColor(row[key])}`}
                                                style={{ padding: "8px", borderRadius: "8px" }}
                                            >
                                                <option value="Open">Open</option>
                                                <option value="Completed">Completed</option>
                                            </select>
                                        ) :
                                            <button className={`status-button ${getStatusColor(row[key])}`}>
                                                {row[key]}
                                            </button>
                                            :
                                            (
                                                row[key]
                                            )}
                                    </td>
                                ))}
                                {user?.user?.type === "user" &&
                                    <td className="action-column ">

                                        <div className={` dropdown ${activeDropdown === rowIndex ? 'active' : ''}`}>
                                            <div className="dropdown-content">
                                                <button onClick={() => handleEdit(row)}>Edit</button>
                                                <button onClick={() => handleDelete(row)}>Delete</button>
                                            </div>
                                        </div>
                                        <i className="bx bx-dots-vertical-rounded" onClick={() => toggleDropdown(rowIndex)}></i>

                                    </td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>

                {isModalOpen && (
                    <BsModal show={isModalOpen} setShow={setIsModalOpen} title={type === 'APPLICANT' ? selectedJob : "Review"}>
                        {
                            type === 'APPLICANT' ?


                                <div style={tableWrapperStyle}>
                                    <table style={applicantsTableStyle} >
                                        <thead>
                                            <tr>
                                                <th style={tableHeaderStyle}>Name</th>
                                                <th style={tableHeaderStyle}>Email</th>
                                                <th style={tableHeaderStyle}>Phone Number</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {applicants.map((applicant, index) => (
                                                <tr key={index}>
                                                    <td style={tableCellStyle}>{applicant.name}</td>
                                                    <td style={tableCellStyle}>{applicant.email}</td>
                                                    <td style={tableCellStyle}>{applicant.phoneNumber}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                :
                                type === "REVIEW" &&
                                <div>
                                    <Select
                                        defaultValue={selectedOption}
                                        onChange={setSelectedOption}
                                        options={applicantList}
                                        placeholder="Select ..."
                                    />
                                    <div className="row " style={{ marginTop: "20px" }}>
                                        <label className="form-label">Description</label>
                                        <div class="df-input-field " style={{ width: "100%" }}>
                                            <textarea rows="10" cols="10" type="text" name="description" placeholder="Company Description"
                                                value={description}
                                                className='form-control'
                                                onChange={handleChangeDescription}
                                            />

                                        </div>

                                    </div>
                                    <div className='row'>
                                        <label className="form-label">Rate</label>
                                        <StarRating totalStars={5} rating={rating} setRating={setRating} />
                                    </div>
                                    <div class="row">
                                        <div class="df-booking2__form-btn mt-15 mb-30">
                                            <button type="submit" class="primary-btn sign-btn w-100"
                                                disabled={description?.length === 0 || selectedOption === null}
                                                onClick={postJobReviews}>
                                                {
                                                    buttonLoader ?
                                                        <ButtonLoader />
                                                        :

                                                        'Post Review'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                        }

                    </BsModal>
                )}


            </div>
        </>
    );
};

export default Table;














// Inline styles for Modal
const modalOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
};

const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '800px',
    width: '100%',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    position: 'relative',
};

const modalCloseStyle = {
    background: 'none',
    border: 'none',
    fontSize: '1.5rem',
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
};

const applicantsTableStyle = {
    width: '100%',
    borderCollapse: 'separate',
    border: '1px solid #eaecf0',
    borderRadius: '12px'
};

const tableHeaderStyle = {
    borderBottom: '2px solid #ddd',
    padding: '18px 10px',
    textAlign: 'left',
    backgroundColor: '#f4f4f4',
};

const tableCellStyle = {
    padding: '18px 10px',
    borderBottom: '1px solid #ddd',
};

const tableWrapperStyle = {
    borderRadius: '12px',
    overflow: 'hidden', // Ensures the rounded corners are visible
    border: '1px solid #eaecf0',
};
